<template>
  <div class="page">
    <top-vue></top-vue>
    <div class="hd">
      <div class="nav">
        <div
          class="nav-item-li"
          v-for="(item, i) in nav"
          :key="i"
          @click="change(i)"
          :class="active == i ? 'active' : ''"
        >
          <a href="javascript:;" class="nav-item-link">{{ item.name }}</a>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <div class="content">     
      <div class="view">
        <div class="title-view">
          <div class="left-icon"></div>
          加盟合作
          <div class="left-icon"></div>
        </div>
        <div class="form">
          <div class="area">
            <b-row class="my-1">
              <b-col sm="2">
                <label>您的姓名 <code style="color: red">*</code> :</label>
              </b-col>
              <b-col sm="10">
                <b-form-input
                  v-model="form.name"
                  type="text"
                  placeholder="请输入您的姓名"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="2">
                <label>手机号码 <code style="color: red">*</code> :</label>
              </b-col>
              <b-col sm="10">
                <b-form-input
                  v-model="form.mobile"
                  placeholder="请输入您的手机号码"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="2">
                <label>留言 :</label>
              </b-col>
              <b-col sm="10">
                <b-form-textarea
                  rows="3"
                  maxlength="50"
                  placeholder="请输入留言 0/50"
                  v-model="form.message"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row class="my-row">
              <b-button style="width: 120px" @click="submit" :disabled="loading"
                >提交</b-button
              >
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <bottom-vue></bottom-vue>
  </div>
</template>
<script>
import topVue from "./header.vue";
import bottomVue from "../../utils/Bottom.vue";
const testReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
export default {
  data() {
    return {
      nav: [
        { name: "首页", path: "/become/index" },
        { name: "加盟流程", path: "/become/flow" },
        { name: "美蜂培训", path: "/become/training" },
        { name: "门店展示", path: "/become/mall" },
      ],
      active: 0,
      slide: 0,
      select: 0,
      form: {
        name: "",
        mobile: "",
        message: "",
      },
      loading: false,
    };
  },
  methods: {
    change(i) {
      this.active = i;
      this.$router.push({
        path: this.nav[i].path
      })
    },
    selectItem(i) {
      this.select = i;
    },
    submit() {
      if (!this.form.name) return this.$store.commit("showToast", "请输入姓名");
      if (!testReg.test(this.form.mobile))
        return this.$store.commit("showToast", "请输入正确的手机号码格式");
      this.loading = true;
      this.$http
        .addmessage(this.form)
        .then((res) => {
          this.loading = false;
          if (res.code == 1) {
            this.$store.commit("showToast", "提交成功");
            setTimeout(() => {
              for (let i in this.form) {
                this.form[i] = "";
              }
              window.scrollTo(0, 0);
              this.$emit("to", 4);
            }, 1000);
          } else {
            this.$store.commit("showToast", res.msg);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  components: {
    topVue,
    bottomVue,
  },
};
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  background: #fff;
}
.hd {
  height: 50px;
  background: linear-gradient(180deg, #fbc2ae 0%, #fe6039 100%);
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0.9;
}
.nav {
  width: 1200px;
  display: flex;
}
.nav-item-li {
  height: 50px;
  line-height: 50px;
  margin-right: 2rem;
  cursor: pointer;
  color: #fff;
}
.active {
  background: #f9b4a1;
}
.nav-item-link {
  display: block;
  color: #ffffff;
  padding: 0 1.5rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.banner {
  height: 700px;
}
.img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.view {
  width: 1200px;
  margin: 20px 0;
  .title-view {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    height: 56px;
    color: #333;
    .left-icon {
      width: 120px;
      height: 5px;
      background: #fe6039;
      opacity: 0.75;
      border-radius: 2.5px;
      margin: 0 20px;
    }
  }

  .show {
    display: flex;
    margin: 10px 0;
    .left-view {
      width: 750px;
      .left-img {
        width: 750px;
        height: 430px;
      }
    }
    .right-view {
      width: 440px;
      margin-left: 10px;
      .right-img {
        width: 440px;
        height: 210px;
      }
      .margin-b10 {
        margin-bottom: 10px;
      }
    }

    .select-view {
      width: 290px;
      margin-right: 10px;
      .item {
        height: 100px;
        border: 1px solid #c0c0c0;
        border-radius: 5px;
        line-height: 100px;
        cursor: pointer;
        text-align: center;
      }
      .item-tree {
        margin-bottom: 10px;
      }
      .item-active {
        background: #fbc2ae;
        color: #fff;
        border-color: #fff;
      }
    }
    .show-view {
      width: 900px;
      .show-view-img {
        width: 900px;
        height: 430px;
      }
    }
  }

  .grils {
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
    .gril {
      width: 275px;
      height: 350px;
      border: 1px solid #ccc;
      text-align: center;
      .gril-img {
        width: 225px;
        // height: 300px;
      }
    }
  }

  .peixun-img {
    width: 200px;
    margin: 10px 50px;
  }
}

.form {
  width: 1200px;
  margin: 20px 0;
  background: url("../../static/about/about-info-bg.jpg") no-repeat;
  height: 500px;
  background-size: 1200px;
  display: flex;
  justify-content: center;
  .area {
    width: 900px;
    height: 400px;
    background: rgba(255, 255, 255, 0.6);
    margin-top: 50px;
    padding: 40px 25px;
  }
  .my-1 {
    height: 50px;
  }
  .my-row {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
</style>
