<template>
  <div class="header">
    <div class="top">
      <div style="width: 1200px; display: flex">
        <div class="select">
          <div v-if="!userinfo">
            <router-link style="text-decoration: none; color: #666" to="/login"
              >登录
            </router-link>
            |
            <router-link style="text-decoration: none; color: #666" to="/login/register"
              >注册</router-link
            >
          </div>
          <div class="sss" v-else id="info" @click="infoPop = !infoPop">
            <img
              class="avatar"
              :src="userinfo.touxiang"
              @error="userinfo.touxiang = require('../../static/my/default_avatar.png')"
            />
            {{ userinfo.nickname }}
          </div>
          <b-popover
            target="info"
            :show="infoPop"
            placement="bottom"
            custom-class="info-pop"
          >
            <div class="info-pop-title" @click="toPage('/my_info')">个人主页</div>
            <div class="info-pop-title" @click="toPage('/order')">我的订单</div>
            <div class="info-pop-title">我的消息</div>
            <div class="info-pop-title" @click="logout">退出登录</div>
          </b-popover>
        </div>
        <b-link class="select" @click="toPage('/doctorInfo')">医生入驻</b-link>
        <b-link class="select" id="download">下载App</b-link>
        <b-link class="select" @click="toJoin">招商合作</b-link>
      </div>
      <b-popover target="download" triggers="hover" placement="bottom">
        <div>
          <img src="../../static/images/down.png" style="width: 120px; height: auto" />
          <div style="" class="download-custem">iPhone | Android</div>
          <div style="width: 120px; text-align: center; height: 32px; line-height: 32px">
            扫码下载app
          </div>
        </div>
      </b-popover>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      infoPop: false
    };
  },
  computed: {
    userinfo() {
      let user = JSON.parse(this.$cache.getCache("userInfo"));
      user.touxiang = this.$host + user.avatar;
      return user;
    },
  },
  methods: {
    logout() {
      this.infoPop = false;
      this.$cache.removeCache("userInfo");
      this.$store.commit("showToast", "退出登录成功");
      setTimeout(() => {
        this.$router.push({
          path: "/login",
        });
      }, 2000);
    },
    toJoin() {
      this.$router.push({
        path: "/become",
      });
    },
    toPage(url) {
      this.infoPop = false;
      this.$router.push({
        path: url,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .top {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background: #f6f6f6;
    display: flex;
    // padding-left: 20%;
    justify-content: center;
    .select {
      width: 160px;
      text-align: left;
      text-decoration: none;
      color: #666;
    }
  }
}
</style>
